/*
Love letter app
 */

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  src: local('Playfair Display Black'), local('PlayfairDisplay-Black'), url(https://fonts.gstatic.com/s/playfairdisplay/v10/UC3ZEjagJi85gF9qFaBgIMGhQI7v0ryGuSQ4oDzNyds.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Courgette';
  font-style: normal;
  font-weight: 400;
  src: local('Courgette Regular'), local('Courgette-Regular'), url(https://fonts.gstatic.com/s/courgette/v5/wEO_EBrAnc9BLjLQAUk1VvoK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #F5F5F5;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

a {
  text-decoration: none;
  color: #E57373;
}

a:hover {

}

.link {
  cursor: pointer;
}

.App-letter {

}

.App-intro {
  font-size: large;
}

.App-footer {

}

.paragraph {
  padding-top: 4em;
}

.fillin-sentence {
  border: 1px solid gainsboro;
}


.print-fillin {
  text-decoration-style: dotted;
  text-decoration-line: underline;
  text-decoration-color: lightgray;
}

.print-fillin.default {
  text-decoration: none;
  padding: 2px 6px;
  background-color: lightcoral;
}

/* Utility functions */
.error {
  color: darkred;
  background-color: lightcoral;
}

.endButtonGrid {
  text-align: right;
}

@media print {
  .App-header,
  .App-footer,
  .endButtonGrid,
  .letterTitle {
    display: none !important;
  }
  .App-letter {
    padding-top: 0 !important;
    flex-grow: 1;
  }
  .print-fillin {
    text-decoration: none !important;
    padding: 0 !important;
  }
  .textSegment {
    line-height: 1.4em !important;
  }
}

.globalLoading {
  position: fixed;
  left: 50%;
  top: 50%;
}
